// Fonts
@font-family-bmx: 'Arial';

// ANTD Overrides
// Colors
@biomerieux-blue: #00427f;
@biomerieux-blueShadow: rgb(0 66 127 / 20%);
@biomerieux-hoverBlue: #badeff; // tint of biomerieux-blue
@biomerieux-backgroundBlue: #e1f1ff; // tint of biomerieux-blue
@biomerieux-lightBlue: rgba(78, 195, 224);
@biomerieux-green: #3c9845;
@biomeriux-softGreen: rgba(60, 152, 69, 0.85);
@biomerieux-greenShadow: rgba(60, 152, 69, 0.15);
@biomerieux-hoverGreen: rgba(60, 152, 69, 0.40);
@biomerieux-darkGray: #5e5e5d;
@biomerieux-coolGray: #a4a5a5;
@biomerieux-lightGray: #e6e7e8;
@biomerieux-hoverGray: #e6e7e8e6;
@background-color: rgba(209, 211, 212, 0.9);
@biomerieux-yellow: #ffdd37;
@biomerieux-lightYellow: #ffffd0;
@gray1: #f0f1f1; // tint of biomerieux-lightGray
@gray-border: #dcdddf; // tint of biomerieux-lightGray
@white-icon-hover: rgba(255, 255, 255, 0.85);

@rp-color: #49c2e2;
@me-color: #f8b431;
@bcid-color: #b3272d;
@gi-color: #cedc28;

@soft-black: rgba(0, 0, 0, 0.85);
@error-red: rgb(187, 0, 0);
@background-red: #ffcfcf; // tint of error-red
@primary-color: @biomerieux-blue;
@biomerieux-link-color: @biomerieux-green;
@highlight-color: @biomerieux-blue;

// Menus
@menu-item-active-bg: rgba(0, 0, 0, 0.08);

// Tables
.ant-table-thead > tr > th {
  text-align: center;
  height: 70px;
  padding: 0;
  background-image: linear-gradient(#fff, @gray1);
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: @gray-border;
}

// Components
.paper {
  padding: 32px;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  flex-grow: 1;
}

// Error Text
.form-error-text {
  margin-top: 8px;
  color: @error-red;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}

::selection {
  background: @biomerieux-blue; // Text selection highlight
  color: #fff;
}

// Buttons
.ant-input-affix-wrapper {
  border: 1px solid @biomerieux-coolGray;
  &:hover, &:focus {
    border-color: @biomerieux-blue;
    box-shadow: 0 0 0 2px @biomerieux-blueShadow;
  }
}

.ant-btn[disabled]:hover {
  color: @soft-black;
  border-color: @biomerieux-coolGray;
  background: @biomerieux-lightGray;
}

.ant-btn:hover, .ant-btn:focus {
  color: @soft-black;
  border-color: @biomerieux-blue;
  box-shadow: 0 0 0 2px @biomerieux-blueShadow;
}

.ant-btn.ant-btn-default {
  text-transform: uppercase;
}

.ant-btn.ant-btn-text, .ant-btn.ant-btn-link.link-as-button, .ant-btn.ant-btn-default {
  color: @soft-black;
  border-color: @biomerieux-blue;
  &:hover, &:focus {
    box-shadow: 0 0 0 2px @biomerieux-blueShadow;
    background: inherit;
    color: @soft-black;
  }
}

// Modals
.ant-drawer-close,
.ant-modal-close,
.ant-modal-close:hover,
.ant-drawer-close:hover,
.ant-drawer-title {
  color: #fff;
}

// Group / pathogen dropdown menus
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.collapse-panel-custom .ant-collapse-borderless .ant-collapse-item:last-child,
.ant-collapse-borderless .ant-collapse-item:last-child .ant-collapse-header, .ant-collapse-borderless {
  border-radius: 5px;
}

// Metric View dropdown style overrides
.ant-collapse-item:hover {
  filter: drop-shadow(8px 8px 5px @biomerieux-coolGray);
}

.ant-menu-item:hover,
.ant-select-item:hover{
  background: @biomerieux-hoverGray;
}

.ant-select-item,
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-top: 4px;
  margin-bottom: 0px;
}

.ant-menu-root.ant-menu-vertical {
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.ant-menu-item-active {
  background-color: @biomerieux-hoverGray;
  color: black;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: @biomerieux-hoverGray;
}

.ant-menu-item,
.ant-menu-item:active,
.ant-menu-item:visited,
.ant-menu-item a:hover {
  color: @soft-black;
}

.ant-menu-item-selected .ant-menu-title-content a,
.ant-select-item-option.ant-select-item-option-selected,
.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
  color: @biomerieux-blue;
  font-weight: 600;
}

// Links
.ant-popover-inner-content a, .bfdx-link, .bfdx-link:hover {
  color: @biomerieux-green;
  text-decoration: underline;
  &:active, &:focus {
    text-decoration: underline;
    color: @biomerieux-blue;
  }
}

// Date Selection
.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker.ant-picker-range {
  border-color: @biomerieux-darkGray;
}

.ant-picker.ant-picker-range:hover,
.ant-picker.ant-picker-range.ant-picker-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: @biomerieux-blue;
  box-shadow: 0 0 0 2px @biomerieux-blueShadow;
}

.ant-picker-suffix,
.ant-picker-header,
.ant-picker-header > button,
.ant-picker-header-view > button {
  color: @biomerieux-darkGray;
}

.ant-picker-header > button:hover,
.ant-picker-header-view > button:hover {
  color: black;
}

.ant-picker-content th {
  background: @biomerieux-lightGray;
}

.ant-picker-header {
  border-bottom: 1px solid @biomerieux-lightGray;
}

.ant-picker.ant-picker-range .ant-picker-active-bar {
  background-color: @biomerieux-blue;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: @biomerieux-blue;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid @biomerieux-blue;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: @biomerieux-greenShadow;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before {
  background: @biomerieux-hoverGreen;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before,
.ant-picker-panel>:not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before,
.ant-picker-panel>:not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before {
  background: @biomerieux-hoverGreen;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top: 1px dashed @biomerieux-hoverGreen;
  border-bottom: 1px dashed @biomerieux-hoverGreen;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed @biomerieux-hoverGreen;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed @biomerieux-hoverGreen;
}

// Media queries
@wide-screen-down: ~'(max-width: 1199px)';
@desktop-down: ~'(max-width: 991px)';
@tablet-down: ~'(max-width: 767px)';
@phone-down: ~'(max-width: 480px)';
